import { __read, __spreadArray } from "tslib";
import { argsOrArgArray } from '../util/argsOrArgArray';
import { raceWith } from './raceWith';
export function race() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return raceWith.apply(void 0, __spreadArray([], __read(argsOrArgArray(args))));
}
